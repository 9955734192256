<template>

  <div class="casa-funeraria-page">
    <div :style="{'background-color': sfondo_3}">
      <b-carousel class="carousel-1-normal" v-if="foto_casa_funeraria !== null && foto_casa_funeraria.length > 0"
                  id="carousel-1"
                  v-model="slide"
                  :interval="4000"
                  controls
                  indicators
                  background="#ababab"
                  style="text-shadow: 1px 1px 2px #333;"
                  @sliding-start="onSlideStart"
                  @sliding-end="onSlideEnd"
      >
        <b-carousel-slide v-for="photo in foto_casa_funeraria"
                          :key="photo.id"
                          :img-src="photo.img + '-/crop/16:9/-/format/auto/'"
        ></b-carousel-slide>
      </b-carousel>
      <div class="container-80">
        <div class="container-68 section-casa-funeraria">
          <h1 class="title-casa-funeraria" :style="{'color': testo_sfondo_3 + '!important'}">Casa Funeraria
            {{ casa_funeraria.nome }}</h1>
          <div class="text-casa-funeraria" :style="{'color': testo_sfondo_3 + '!important'}">
            {{ casa_funeraria.descrizione }}
          </div>
        </div>
        <!-- Rooms -->
        <!-- <div class="principal-room">
          <img v-if="foto_casa_funeraria" :src="foto_casa_funeraria[indexImgAgency].img" alt="">
        </div> -->
      </div>
    </div>

    <!-- <div :style="{'background-color': sfondo_3}">
      <div class="other-rooms" :style="{'background-color': sfondo_3}">
        <div class="rooms-style">
          <div v-for="(item, indexImg) in foto_casa_funeraria" :key="item.id">
            <div class="item-room">
              <img
                  class="foto-stanza-funeraria"
                  alt=""
                  :src="item.img"
                  @click="changeImgAgency(indexImg)"
              >
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <!-- /Rooms -->
    <!-- Description Rooms -->

    <div
        class="description-rooms"
        :style="{'background-color': sfondo_1 }">
      <div v-if="texture" class="texture">
        <img :src="texture" alt="">
      </div>
      <div class="container-80 list-rooms"
           :style="{'color': testo_sfondo_1 + '!important', 'border-bottom': '1px solid' + testo_sfondo_1 }">
        <span v-for="(item, index) in stanze_funerarie" :key="item.id" @click='changeRooms(index)'>
          Stanza {{ index + 1 }}
        </span>
      </div>
      <div v-for="(item, index) in stanze_funerarie" :key="item.id" class="container-80 info-rooms"
           v-show="show === index">
        <div v-if="item.img_stanza !== ''" class="img-room-casa-funeraria">
          <img :src="item.img_stanza[indexRoom] + '-/crop/16:9/-/format/auto/'" :alt="item.nome_stanza">
        </div>
        <div class="description-logo-nome">
          <div class="container-nome-logo-stanza">
            <span :style="{'color': testo_sfondo_1 + '!important'}">{{ item.nome_stanza }}</span>
            <img v-if="item.foto_stanza !== ''" class="logo-stanza" :src="item.foto_stanza" :alt="item.nome_stanza">
          </div>
          <div v-if="item.descrizione !== ''" class="info-description-room"
               :style="{'color': testo_sfondo_1 + '!important'}">
            {{ item.descrizione }}
          </div>
          <div class="item-room-info">
            <div class="container-foto-stanze" v-for="(el, indexImgRoom) in item.img_stanza" :key="el.id">
              <img
                  v-if="item.img_stanza !== ''"
                  class="foto_stanze"
                  alt=""
                  :src="el"
                  @click="changeImgRooms(indexImgRoom)"
              >
            </div>
          </div>
        </div>
      </div>

      <!-- Stanze mobile -->
      <div v-for="(item, index) in stanze_funerarie" :key="item.id" class="" v-show="show === index">
        <div v-if="item.img_stanza !== ''" class="img-room-casa-funeraria-mobile">
          <img :src="item.img_stanza[indexRoom] + '-/crop/16:9/-/format/auto/'" :alt="item.nome_stanza">
        </div>
        <div v-if="item.img_stanza !== ''" class="item-room-info-mobile">
          <div class="container-foto-stanze-mobile" v-for="(el, indexImgRoom) in item.img_stanza" :key="el.id">
            <img
                class="foto_stanze-mobile"
                alt=""
                :src="el"
                @click="changeImgRooms(indexImgRoom)"
            >
          </div>
        </div>
      </div>
    </div>
    <!-- /Description Rooms -->

    <!-- Address and Map -->
    <div class="map-casa-funeraria" :style="{'background-color': sfondo_3 }">
      <div class="container-80 structure-map">
        <b-row class="all-services-casa-funeraria">
          <b-col md="3" cols="12">
            <div class="info-maps">
              <h4 class="address" :style="{'color': testo_sfondo_3 + '!important'}">{{ via }} - {{ cap }} {{
                  comune
                }}</h4>
            </div>
          </b-col>
          <b-col md="7" offset-md="2" cols="12">
            <div class="maps">
              <l-map style="height: 400px; width:100%; border-radius: 25px;"
                     gestureHandling
                     :options="mapOptions"
                     :zoom="zoom"
                     :center="geolocation">
                <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
                <l-marker :lat-lng="geolocation"></l-marker>
              </l-map>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>


<script>
import {GestureHandling} from "leaflet-gesture-handling";
import {LMap, LTileLayer, LMarker} from 'vue2-leaflet';
import axios from "axios";
import {resolvePhoto} from '@/services/utils';

const LIST_STANZE_FUNERARIE = '/dashboard/api/v0/stanzafunerarie/list?casa_funeraria=';
const GET_CASA_FUNERARIA = '/dashboard/api/v0/casafuneraria/get/';
import {mapGetters} from "vuex";


export default {
  name: "CasaFuneraria",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    // eslint-disable-next-line vue/no-unused-components
    GestureHandling
  },

  data() {
    return {
      stanze_funerarie: null,
      casa_funeraria: null,
      slide: 0,
      foto_casa_funeraria: [],
      url: 'https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoibWFyb2NjbzEyIiwiYSI6ImNsazc1ejNyaDA3NzkzdHA2cmppNWN4MzUifQ.PGAGDXVwWq6njX4L3EUw7w',
      attribution: '',
      zoom: 17,
      show: 0,
      indexRoom: 0,
      indexImgAgency: 0,
      mapOptions: {
        zoomSnap: 0.5,
        gestureHandling: true
      }
    }
  },
  computed: {
    ...mapGetters([
      'nome_azienda',
      'via',
      'cap',
      'comune',
      'chi_siamo',
      'geolocation',
      'testo_sfondo_3',
      'testo_sfondo_2',
      'testo_sfondo_1',
      'sfondo_1',
      'sfondo_3',
      'sfondo_2',
      'texture',
      'casa_funeraria',
      'stanze_funerarie'
    ]),
  },
  metaInfo() {
    return {
      title: 'Case Funeraria - ' + this.nome_azienda,
      meta: [
        {
          name: 'description',
          content: this.chi_siamo,
        },
        {property: 'og:title', content: this.nome_azienda},
        {property: 'og:site_name', content: ''},
        {
          property: 'og:description',
          content: this.chi_siamo
        },
        {property: 'og:type', content: ''},
        {property: 'og:url', content: ''},
        {property: 'og:image', content: ''}
      ]
    }
  },
  methods: {

    changeImgAgency: function (index) {
      this.indexImgAgency = index;
    },

    // eslint-disable-next-line no-unused-vars
    onSlideStart(slide) {
      this.sliding = true
    },
    // eslint-disable-next-line no-unused-vars
    onSlideEnd(slide) {
      this.sliding = false
    },

    changeRooms: function (index) {
      this.show = index;
      this.indexRoom = 0;
    },

    changeImgRooms: function (index) {
      this.indexRoom = index;
    },

    async photosStanzeFunerarie() {
      return await resolvePhoto(this.casa_funeraria.foto)
    },

    showhideText: function () {

      this.text_visibility = document.getElementById("description");
      this.text_visibility.style.display = 'block';
      this.text_visibility.style.margin = '0 0 30px 0';
      this.text_visibility = document.getElementById("readAll");
      this.text_visibility.style.display = 'none';
    },
  },
  mounted() {

    let casa_funeraria_id = this.$router.history.current.params.id;

    // eslint-disable-next-line no-undef
    axios.get(GET_CASA_FUNERARIA + casa_funeraria_id)
        .then(async (res) => {
          console.log(res.data.results)
          this.casa_funeraria = res.data.results[0];
          this.foto_casa_funeraria = await resolvePhoto(this.casa_funeraria.foto)
        })

    // eslint-disable-next-line no-undef
    axios.get(LIST_STANZE_FUNERARIE + casa_funeraria_id)
        .then(async (res) => {

          let results = res.data.results;
          for (let k of results) {

            let img = []
            if (k.img_stanza !== undefined && k.img_stanza.length > 0) {
              let photos = await resolvePhoto(k.img_stanza)
              for (let f of photos)
                img.push(f.img)
            }
            k.img_stanza = img;
          }
          this.stanze_funerarie = results;
        })
  }
}
</script>


<style scoped>
html, body {
  width: 100%;
  margin: 0;
  padding: 0;
}

.container-68 {
  width: 68%;
  margin: auto;
}

.texture > img {
  position: absolute;
  height: 100%;
  /* opacity: 0.1; */
  width: 100%;
  object-fit: cover;
}

.section-casa-funeraria {
  text-align: center;
  padding-top: 30px;
}

.title-casa-funeraria {
  text-transform: uppercase;
  color: #262B5B;
}

.text-casa-funeraria {
  font-size: 18px;
  color: #262B5B;
  margin-top: 40px;
  padding-bottom: 40px;
}

/* Rooms */
.principal-room {
  width: 100%;
  position: relative;
  padding-bottom: 56.2%;
}

.principal-room img {
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 25px;
}

.item-room {
  /* width: 70px;
  height: 70px; */
  border-radius: 15px;
  /* margin-right: 30px; */
  background-color: #292D5D;
}

.item-room > img {
  width: 150px;
  height: 150px;
  border-radius: 25px;
  object-fit: cover;
}

.other-rooms {
  padding-top: 40px;
  padding-bottom: 60px;
  background-color: white;
  text-align: center;
  margin-left: 10%;
  overflow: auto;
}

.rooms-style {
  display: flex;
  justify-content: space-between;
  animation-duration: 5s;
  animation-name: slidein;
}

/* /Rooms */

/* Description-rooms */
.description-rooms {
  padding-bottom: 60px;
  position: relative;
  /* background-color: #262B5B; */
}

.list-rooms {
  display: flex;
  justify-content: space-between;
  overflow: scroll;
  font-size: 1.2vw;
  padding-top: 30px;
  padding-bottom: 10px;
  border-bottom: 1px solid white;
  text-transform: uppercase;
  color: white !important;
}

.list-rooms > span {
  z-index: 1;
  padding-right: 20px;
  padding-left: 20px;
  text-align: center;
  cursor: pointer;
}

.info-rooms {
  position: relative;
  display: flex;
  color: white !important;;
}

.img-room-casa-funeraria {
  width: 65%;
  position: relative;
  margin-top: 40px;
  /* padding-bottom: 56.2%; */
}

.img-room-casa-funeraria-mobile {
  display: none;
}

.img-room-casa-funeraria img {
  /* position: absolute; */
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 25px;
}

.description-logo-nome {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 35%;
  margin-top: 40px;
}

.container-nome-logo-stanza {
  display: flex;
  justify-content: space-between;
  margin-left: 30px;
}

.container-nome-logo-stanza span {
  font-size: 2vw;
  font-weight: bold;
  margin-top: 20px;
}

.logo-stanza {
  width: 150px;
  height: 150px;
  border-radius: 15px;
  background-color: white;
}

.info-description-room {
  height: 180px;
  overflow-y: auto;
  font-size: 18px;
  margin-top: 40px;
  margin-left: 30px;
}

.item-room-info {
  display: flex;
  justify-content: space-between;
  margin-top: 12%;
  overflow: auto;
  scrollbar-width: none;
}

.item-room-info::-webkit-scrollbar {
  display: none;
}

::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

.item-room-info-mobile {
  display: none;
}

.container-foto-stanze {
  padding-left: 30px;
}

.foto_stanze {
  width: 150px;
  height: 150px;
  border-radius: 25px;
  -o-object-fit: cover;
  object-fit: cover;
}

/* /Description-rooms */

.all-services-casa-funeraria {
  padding-top: 40px;
  padding-bottom: 40px;
  justify-content: space-between;
}

.map-casa-funeraria .agency-map {
  background-image: linear-gradient(to right, white, white);
}

/* .map-casa-funeraria .info-maps {
  color: #262B5B;
} */

/* MEDIA QUERY */

@media (min-width: 1024px) {
  .other-rooms {
    width: 80%;
    margin: auto;
  }
}

@media (max-width: 1023.98px) {
  .container-68 {
    width: 100%;
  }

  .other-rooms {
    margin: 0;
  }

  .item-room {
    margin-left: 20px;
  }

  .item-room:last-child {
    margin-right: 20px;
  }

  .list-rooms {
    font-size: 3vw;
  }

  .img-room-casa-funeraria {
    display: none;
  }

  .img-room-casa-funeraria-mobile {
    display: block;
    margin-top: 40px;
  }

  .img-room-casa-funeraria-mobile > img {
    width: 100%;
  }

  .description-logo-nome {
    width: 100%;
  }

  .container-nome-logo-stanza > span {
    font-size: 6vw;
    margin-bottom: 30px;
  }

  .container-nome-logo-stanza {
    flex-direction: column;
    align-items: center;
    margin: 0;
    font-size: 3vw;
  }

  .info-description-room {
    height: 130px;
    text-align: center;
    margin-left: 0;
    font-size: 18px;
  }

  .item-room-info {
    display: none;
  }

  .item-room-info-mobile {
    display: flex;
    margin-top: 40px;
    overflow: auto;
  }

  .foto_stanze-mobile {
    width: 150px;
    border-radius: 25px;
  }

  .container-foto-stanze-mobile {
    margin-left: 20px;
    margin-right: 20px;
    z-index: 99;
  }

}

</style>
