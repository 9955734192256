import axios from "axios";

const URL_RESOLVE_PHOTO = 'https://upload.uploadcare.com/group/info/?pub_key=' +
    process.env.VUE_APP_UPLOADCARE_KEY;


function dataNascitaNecrologio(data_nascita) {
    let d = new Date(data_nascita)
    return ("0" + d.getDate()).slice(-2) + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" +
        d.getFullYear()
}

function dataMorteNecrologio(data_morte) {
    let d = new Date(data_morte)
    return ("0" + d.getDate()).slice(-2) + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" +
        d.getFullYear()
}

function giornoOraMessa(giorno_ora_messa) {
    let d = new Date(giorno_ora_messa)
    return ("0" + d.getDate()).slice(-2) + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" +
        d.getFullYear() + " " + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2);
}

function calculateYears(data_nascita, data_morte) {

    if (data_nascita !== undefined && data_morte !== undefined) {
        data_nascita = new Date(data_nascita).getFullYear()
        data_morte = new Date(data_morte).getFullYear()
        return data_morte - data_nascita;
    } else {
        return 0;
    }
}

function getPhotoNecrologioFromCdn(item) {
    return process.env.VUE_APP_CDN_URL + item.foto.foto_home_necrologi_desktop
}

export async function resolvePhoto(group_photos_url) {

    let photos = []
    // eslint-disable-next-line no-undef

    let response = await axios.get(URL_RESOLVE_PHOTO + '&group_id=' +
        group_photos_url.replace('https://ucarecdn.com/', '').replace('/', ''))

    let index = 0;
    for (const obj of response.data.files) {
        let img = 'https://ucarecdn.com/'
        photos.push({
            'id': index++,
            'img': img + obj.uuid + "/"
        })
    }

    return photos;
}


export default {
    dataNascitaNecrologio,
    dataMorteNecrologio,
    giornoOraMessa,
    calculateYears,
    getPhotoNecrologioFromCdn,
    resolvePhoto
}
